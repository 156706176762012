.wrapper{
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 35px;
    padding-right: 35px;
}
/* padding: "36px 60px", backgroundColor: "#141414"  */
@media screen and (max-width:1211px) {
    .wrapper{
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 25px;
    }
}