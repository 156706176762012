@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
      .button-arrow{
    height: "30px";
    width: "30px";}
}

@media only screen 
  and (min-device-width: 820px)  { 
      .button-arrow{
    height: "60px";
    width: "60px";}
}