.menu{
    /* border: solid 1px red; */
    text-align: left;
    width: 96%;
    margin: 0 auto;
}
/* NavBar */
.navbar{
    padding-top:0;
    padding-bottom: 0;
    z-index: 98;
    max-width: 1231px;
    margin: 0 auto;
    /* position: relative; */
}
.navbar0{
    padding-top:0;
    padding-bottom: 0;
    z-index: 98;
}
/* .navbar::before{
    content: '';
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
} */
@media screen and (max-width:1200px) {
    .navbar, .navbar0{
        padding-bottom: 15px;
    }
}
/* ifa logo */
.logo{
    position:absolute;
    top: -8px;
    width: 261px;
    z-index: 99;
}

/* space before the logo */
.space{
    width: 209px;
    margin-right: 15em;
}

/* space before social media icons */
.sm-space{
    width: 48px;
}
.cnx{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* add some space between the connexion btn and the right edge for large screens */
@media screen and (min-width:1200px) {
    .cnx{
        padding-right: 20px;
    }
}

@media screen and (min-width: 991.98px) and (max-width: 1183px) {
    
    .space{
        margin-right: 10em;
    }
    .logo{
        width: 40px;
        /* transform: translateX(-10px); */
    }
    /* drapeau france */
    .flags.fr{
        /* background-color: red; */
        transform: translateX(4rem);
        margin-right: 10px;
    }
    /* drapeau algérie */
    .flags.ar{
        /* background-color: red; */
        transform: translateX(3.4rem);
        margin-left: 10px;
    }
    /* the container of villes */
    .cityNav{
        transform: translateX(-3rem);
        gap: 0px;
    }
    /* social media icons */
    .sm-icon{
        /* transform: translateX(-20px); */
        margin-right: -2px;
    }
    /* space before social media icons */
    .sm-space{
        width: 5px;
    }
    /* ==== menu items with drop down menu */
    .menu-items-list{
        /* transform: translateX(-50px); */
    }
    
}

/* translate the nav-items-list to the left */
@media screen and (min-width: 991.98px) and (max-width: 1043px){
    .menu-items-list{
        /* transform: translateX(-40px); */
        margin-right: 40px;
    }
}

/* Responsivity issue */
/*  width < 992 : ok
 width > 1183 : ok
 992 < width < 1183 : problem */

 .back-header{
    background-color: #212529;
    height: 40px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 15;
 }

 @media screen and (min-width:1230px) {
     .back-header2{
        background-color: #3558a2;
        height: 55.375px;
        position: fixed;
        top: 40px;
        width: 50%;
        z-index: 15;
        right: 0;
     }
    
 }