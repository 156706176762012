/*======= cours de francais + test et examens =====*/
.select_item:hover{
    background-color: rgb(240, 240, 240);
}

/*========= Main Title ========*/
.title{
    color:rgb(53, 88, 162);
    font-weight: bold;
    /*text-align: left;*/
}

/* ======== DossierInscriptionCard ========= */
.dossier ul{
    font-size: 14px;
}

/* ======== Contact Card =========== */
.contact ul{
    font-size: 14px;
}

/* ======= Inscription Card ======== */
.inscription p {
    font-size: 14px;
}