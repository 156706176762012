header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: white;
  }
  
  .nav_area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
  }
  
  .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
  }
  
  .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .menu_items {
    position: relative;
    font-size: 14px;
    background-color: rgb(53, 88, 162);
  }
  
  .menu_items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
  }
  
  .menu_items button {
    display: flex;
    align-items: center;
    color: inherit;
    /* font-size: inherit; */
    font-size: 16px;

    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu_items > a, .menu_items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  @media screen and (min-width: 991.98px) and (max-width: 1183px){
    .menu_items > a, .menu_items button {
      padding: 0.7rem 0.5rem 0.7rem 0.5rem;
    }
    
  }
  
  .menu_items.dropItem a:hover,
  .menu_items.dropItem button:hover {
    background-color: #f2f2f2;
    color:rgb(53, 88, 162);
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    /* padding: 0.5rem 0; */
    padding: 0 0;
    list-style: none;
    background-color: rgb(53, 88, 162);
    /* border-radius: 0.5rem; */
    
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  /* ====== Drop down style for Etudes */
  .dropdown_etudes {
    position: absolute;
    right: -200px;
    /* left: auto; */
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: -9999;
   
    /* min-width: 10rem; */

    padding:  2.5rem 1rem 2rem;
    list-style: none;
    background-color: rgb(53, 88, 162);
    /* border-radius: 0.5rem; */
    transform: translateY(-100px);
    display: none;
    transition: all ease .2s;
  }

  .dropdown_etudes.show {
    display: flex;
    gap: 10px;
    transform: translateY(0);
    z-index: 100;

  }

  .dropdown-etudes-grid{
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }

  .dropdown-etudes-grid ~ .dropdown-etudes-grid{
    margin-left: 20px;
  }

  .dropdown-etudes-grid h6{
    position: absolute;
     top: -30px;
    /*left: 50%; */
  }
  .dropdown-etudes-grid h6 a{
    font-size: 17px;
    text-transform: uppercase;

  }

  .dropdown.show_etudes{
    display: flex;
    color: #cc3852;
  }
  
  .dropdown .dropdown_submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  /* horizontal submenu*/
  .dropdown.show_etudes{
    top:100%;
    left: 0;
  }

  .cardImg{
    width: 100%;
    transition: all .2s ease-in;
  }

  .cardImg:hover{
    transform: rotateZ(3deg);
    scale: 1.1;
  }

  .enFrance{
    position: absolute;
    top: 7px;
    left: 1rem;
    /* font-weight: bold; */
    font-size: 17px;
  }

  .enAlgerie{
    position: absolute;
    top: 7px;
    left: 50%;
    /* font-weight: bold; */
    font-size: 17px;
  }


  /* ================================== */
  
  /* content */
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h2 {
    margin-bottom: 1rem;
  }
  
  .content a {
    color: #cc3852;
    margin-right: 10px;
  }