.card{
    display: flex;
    align-items: center;
    padding: 0.8rem ;
    border-radius: 3px;
    background-color: white;
    width: 400px;
    height: 100%;
    gap: 0.3rem;
    /* width:min(400px,100%) */
  }

  @media screen and (max-width:400px) {
    /* .card{
      width: 100%;
      background-color: red;
    } */
  }
  .card img{
    width: 150px;
  }