.overlay{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,.7);
    z-index:100;
}

.modal{
    position:fixed;
    width:500px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index:200;
}

@media screen and (max-width:500px) {
    .modal{
        width: 90vw;
    }
}