.hover-custom::after {
    content: '';
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
    /*border: 1px solid #fff;*/
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 90;
    transition: all 0.3s;
    transform: scale(1.1);
    opacity: 0;
    display: block;
  }
  
  .hover-custom-content {
    position: absolute;
    bottom: 25px;
    left: 5px;
    width: 100%;
    transform: translateY(-50%);
    /*text-align: center;*/
    z-index: 97;
  }
  
  .hover-custom-description {
    opacity: 0;
    transform: scale(1.3);
    transition: all 0.3s;
  }
  
  .hover-custom img {
    width: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hover-custom .hover-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .hover-custom:hover img {
    width: 100%;
  }
  
  .hover-custom:hover::after {
    opacity: 1;
    transform: none;
  }
  
  .hover-custom:hover .hover-custom-description {
    opacity: 1;
    transform: none;
  }
  
  .hover-custom:hover .hover-overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .hover {
    overflow: hidden;
    position: relative;
    /*padding-bottom: 60%;*/
  }
  
  .hover-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
  }
  
  .hover img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
  
  .hover-content {
    position: relative;
    z-index: 99;
  }

  .iden-img6 {
    background-image: url(../../assets/img/identity-music.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img7 {
    background-image: url(../../assets/img/identity-gastro.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img4 {
    background-image: url(../../assets/img/identity-franco.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img2 {
    background-image: url(../../assets/img/identity-arabe.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img5 {
    background-image: url(../../assets/img/identity-ecology.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img8 {
    background-image: url(../../assets/img/identity-patrimoine.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img9 {
    background-image: url(../../assets/img/identity-numerique.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .iden-img1 {
    background-image: url(../../assets/img/identity-nuit.png);
    background-size: cover;
    background-repeat: no-repeat;
  }