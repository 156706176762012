.card:hover .role_container{
    top: 0;
}
.role_container{
    width:100%;
    height: 100%;
    background-color: rgba(0, 145, 213, 0.575);
    position: absolute;
    top:100%;
    left: 0;
    transition: all ease 0.3s;
    text-align: center;
    
}
.role{
    color:white;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
}
