
.carousel_img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

@media screen and (min-width:1200px) {
    .carousel{
        height: 100vh; 
    }
    
}

/* ============ SVG MAP ======== */
/* .campus{
    fill:rgb(7, 167, 241);
} */
path.campus:hover{
    cursor: pointer;
    fill:#0285c2;
}

path{
    transition: all ease 0.3s;
    stroke: grey;
}
path:hover{
    fill: rgb(214, 214, 214);
}


/*======= 58 wilaya svg map =======*/
:root{
    --react-algeria-map-hover-fill : white;
    --react-algeria-map-hover-stroke : dodgerblue;
  }
  .state {
    transition: 0.5s;
  }
  :hover.state {
    /* fill: var(--react-algeria-map-hover-fill,white); */
    fill: rgb(5, 147, 212);
    /* stroke: var(--react-algeria-map-hover-stroke,dodgerblue); */
    stroke:rgb(5, 147, 212) ;
    stroke-width: 4px;
    cursor: pointer;
    
  }
  :hover.campus{
    fill: #08437e;
  }

  .active{
    transform: scale(1.005);
  }

  /* =========== BANDEAU COMPONENT ========= */
  .bandeau{
    background-color: rgb(205, 232, 252);
  }