.icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.item{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.select_item:hover{
    background-color: rgb(240, 240, 240);
}

/* =========== Detail Cours ======== */
.no-course{
    /* padding: 20vh 0; */
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* ======== Tous les cours card ======== */
.all-courses-card{
    transition: all ease .3s;
    position: relative;
    min-height: 100px;
}
.all-courses-card:hover{
    filter:saturate(.7); 
}
.all-courses-card::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border : #fff 1px solid;
    transition: all ease .3s;

}
.all-courses-card:hover::before{
    transform: scale(0.9);
}