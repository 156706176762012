.card-img-top {
    
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
  }
  .zoom-effect-container {
    overflow: hidden;
    position: relative;
    max-height: 310px;
  }
  .zoom-effect-container:hover .card-img-top {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  .card:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
  }