.breadcrumb{
    
    display: inline-block;
    padding: 0;
    margin: 0;
    background: transparent;
    overflow: hidden;
}
.breadcrumb li{
    float: left;
    padding: 8px 15px 8px 50px;
    background: #67c1eb;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: relative;
}
.breadcrumb li:first-child{ background: #a5daf2; }
.breadcrumb li:last-child{
    background: #3558a2;
    margin-right: 18px;
}
.breadcrumb li:before{ display: none; }
.breadcrumb li:after{
    content: "";
    display: block;
    border-left: 18px solid #67c1eb;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    position: absolute;
    top: 0;
    right: -18px;
    z-index: 1;
}
.breadcrumb li:first-child:after{ border-left-color: #a5daf2; }
.breadcrumb li:last-child:after{ border-left-color: #3558a2; }
.breadcrumb li a{
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}
/* @media only screen and (max-width: 479px){ */
    @media only screen and (max-width: 700px){
    /* .breadcrumb li{ padding: 8px 15px 8px 30px; } */
    .breadcrumb{
        margin-top: 0.5rem;
        margin-left: 1rem;
    }
    .breadcrumb li{ 
        padding: 8px 15px 8px 3px; 
        background-color: transparent;
        color: black;
        font-weight: 500;
    }
    .breadcrumb li:after{
        content: ">";
        display: block;
        /* border-left: 18px solid #67c1eb; */
        border-left: 18px solid transparent;
        top: -10px;
        right: 5px;
    }
    .breadcrumb li:first-child{ 
        background: transparent;
      
     }
    .breadcrumb li:last-child{
        background: transparent;
        margin-right: 18px;
    }
    .breadcrumb li a{
        color: black;
        font-weight: 500;
    }
    .breadcrumb li:first-child:after{ border-left-color: transparent; }
.breadcrumb li:last-child:after{ 
    border-left-color: transparent;
    content:""
 }
}