.card{
    transition: all ease .3s;
    position: relative;
    min-height: 100px;
}

.card::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all ease .3s;
    z-index: 3;
}
.card::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all ease .3s;
    background-color: rgb(0, 0, 0);
    opacity: 0.25;
}
.card:hover::before{
    transform: scale(0.9); 
    border : #fff 1.5px solid;
}

.card:hover::after{
    opacity: 0.373;
}

.card span{
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: 1.5rem;
    z-index: 3;
}