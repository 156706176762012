.title{
    color: rgb(53, 88, 162);
    font-weight: bold;
    text-align: left;
  }

  .question{
    width: 62%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }

  .col{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .item{
    display: flex;
    align-items: center;
    gap:1rem;
  }

  .examens{
    background-color:whitesmoke;
    padding: 3rem 0;
    
  }

  .select_item:hover{
    background-color: rgb(240, 240, 240);
}

/* ========= CalendrierTestCard ======== */
.calendrier{
  font-size: 14px;
}
.calendrier span{
  display: block;
}