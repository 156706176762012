p{
  font-size: 1.1rem;
 }

 a{
  text-decoration: none;
 }

 .campus{
  width:100px;
  height: auto;
  margin-left: 12px;
 }

 /* =========== CAMPUS FRANCE =========== */
 .bandeau{
  margin:5rem 0;
 }

 /* ========== BOURSES =========== */
 .bourseCard{
  padding: 1.2rem;
 }
 .bourseCard h5{
  color: rgb(53, 88, 162);
 }

 /* ========= LIAD =========== */

 .liad p{
  line-height: 2;
}
.liad a{
  color: rgb(53, 88, 162);

}

/* ========== ESAA ========= */
.essa p {
  line-height: 2;
}
.essa h4{
  font-weight: bold;
  color: rgb(53, 88, 162);
}