/* ====== CONTACT CARD ====== */

.contact p{
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.contact p:last-of-type{
    margin-bottom: 0;
}