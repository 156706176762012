.carousel_img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

@media screen and (min-width:1200px) {
    .carousel{
        height: 100vh; 
    }
    
}