
.menu{
    width: 600px;
}

@media screen and (max-width: 1260px) and (min-width: 968px) {
    .menu{
        left:13rem;
        width:50%;
    }
}
@media screen and (max-width: 968px) {
    .menu{
        display: none;
    }
}