
/* .App {
  text-align: center;
} */
.app{
  /* background-color: blue; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main{
  /* background-color: yellow; */
  min-height: calc(100vh - 195px);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.iden-img1 {
  background-image: url(../public/ComponentTMP_3-image.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.cultureteque-img {
  background-image: url(assets/img/cultureteque.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.decalog-img {
  background-image: url(assets/img/decalog.png);
  background-size:  contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.region-img-oran {
  background-image: url(assets/img/oran2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.region-img-alger {
  background-image: url(assets/img/alger4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.region-img-constantine {
  background-image: url(assets/img/constantine5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.region-img-tlemcen {
  background-image: url(assets/img/tlemcen1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.region-img-annaba {
  background-image: url(assets/img/annaba1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  .video-slide {
    width: 100%;
    height: 20em;
    position: relative
  }
}
@media only screen and (min-width: 600px) {
  .video-slide {
    width: 100%;
    height: 20em;
    position: relative
  }
}
@media only screen and (min-width: 768px) {
  .video-slide {
    width: 100%;
    height: 30em;
    position: relative
  }
}

@media only screen and (min-width: 992px) {
  .video-slide {
    width: 100%;
    height: 40em;
    position: relative
  }
}
@media only screen and (min-width: 1200px) {
  .video-slide {
    width: 100%;
    height: 723px;
    position: relative
  }
}
/* ======== Colors =======*/
.blue-ifa{
  color: rgb(53, 88, 162);
}

.bg-blue-ifa{
  background-color: rgb(53, 88, 162);
}

.bg-blue-ifa-light{
  background-color:rgb(205, 232, 252);
}

.bg-grey{
  background-color: whitesmoke;
  
}

.red-ifa{
  color: #E3041B;
}

.bg-red-ifa{
  background-color: #E3041B;
}

/* ======== Font sizes =======*/
.fs-16{
  font-size: 16px;
}
.fs-15{
  font-size: 15px;
}
.fs-14{
  font-size: 14px;
}
.fs-13{
  font-size: 13px;
}
.fs-12{
  font-size: 12px;
}
/*========== Z-INDEX ============*/
.z-2{
  z-index: 2;
}
.z-10{
  z-index: 10;
}

.cursor-pointer{
  cursor: pointer;
}

/* ========== BACKGROUND SHAPES ========== */
.bg-shapes{
  /* background-image:url("./assets/img//bg-shapes-svg/svgexport-20.svg");
  background-position: right top;
  background-repeat: no-repeat; */
  /* background-size: 100px; */
  position: relative;
  overflow: hidden;
}
.bg-shapes-red-right-top,.bg-shapes-blue-left-bottom{
  position: relative;
}

.bg-shapes-red-right-top::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 300px;
  right: -750px;
  z-index: -2;
  background-image: url("./assets/img//bg-shapes-svg/shape-red.svg");
  background-repeat: no-repeat;
  transform: rotate(50deg);

}
.bg-shapes-blue-left-bottom::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 600px;
  left: -1200px;
  z-index: -2;
  background-image: url("./assets/img//bg-shapes-svg/shape-blue.svg");
  background-repeat: no-repeat;
  transform: rotate(120deg);
}

/* ====== React Leaflet ====== */
.leaflet-container {
  width: 100%;
  height: 250px;
  z-index: 5;
  overflow: hidden;
}

/* .leaflet-popup-content-wrapper{
  border-radius: 10px;
} */
/* .leaflet-popup-content-wrapper h6{
  text-transform: capitalize;
} */
/* .leaflet-popup-content-wrapper svg{
  fill: red;
} */

/* ================================== */
/* ======== AEC integration ========= */
/* ================================== */

/* ===== Edit the form in "IdentificationPage" ie: /identification ===== */
.reminder-form form{
  width: fit-content;
  margin: auto ;
  margin-top: -4rem;
}

/* ====== Edit the form in "UpdatePasswordPage" ie: /changer-le-mot-de-passe ======= */
.update-password-form form{
  width: fit-content;
  margin: auto ;
  margin-top: -4rem;
}

/* ====== Edit the form in "ReminderUpdatePassword" ie: /réinitialisation-de-mot-de-passe  */
.password-reset-form form{
  width: fit-content;
  margin: auto ;
  margin-top: -4rem;
}

/*  drop down btn */
button.activity-filter-btn.btn-primary:focus {
  box-shadow: 0 0 0 0 0 rgba(255, 255, 255, 0);
box-shadow:none;
outline: unset !important;
}

.activity-filter-btn{
  width: calc(187.625px + 32px);
  background-color: whitesmoke !important;
  color: black !important;
}
.activity-filter-btn:focus{
  /* background-color: red !important; */
  box-shadow: none !important;
  border: none;
}