.title{
  color: rgb(53, 88, 162);
  font-weight: bold;
  text-align: left;
}

p{
    margin-bottom: 0.9rem;
    line-height: 1.5;

}
/* shapes in the background *
.bg_shapes{
  background-image:url("../../assets/img/bg-shapes/shape2-yellow.png"),url("../../assets/img/bg-shapes/shape-red.png");
  background-position: right top, center;
  background-repeat: no-repeat, no-repeat;
  background-size: 300px,50px ;
  position: relative;
  
}
.bg_shapes::before{
  content: "";
  position: absolute;
  top: 50%;
  bottom: -50%;
  left: 100px;
  z-index: 1;
  background-image: url("../../assets/img/bg-shapes/shape2-yellow.png");

}*/

.section{
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.missions{
  /* max-width: 1090px; */
  /* margin: 0 auto; */
  /* border: solid 1px red; */
}

/********* Equipe page **********/

.title{
  color: rgb(53, 88, 162);
  font-weight: bold;
  text-align: left;
}

.histoire p{
  font-size: 1.1rem;
   text-align: left;
}

.card{
  display: flex;
  align-items: center;
  padding: 1rem ;
  border-radius: 3px;
  background-color: white;
  width: fit-content;
  gap: 1rem;
}

.card img{
  width: 150px;
}
